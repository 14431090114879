<template>
<div class="charting" style="height:100%; position:relative;">
    <el-col :xs="24" :sm="6" class="left-box">
      <div class="row-wrap" :style="{height: isMinScreen?(docHeight+33)+'px':docHeight+'px'}" style="margin-left:2px;">
        <el-table :data="settings" style="width: 100%" size="mini" ref="rootTable"  stripe @row-click="selectRow">
          <el-table-column prop="label" :label="msg('设置')" min-width="130"></el-table-column>
          <el-table-column min-width="100">
            <template slot="header"> 
                <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="add(root)">{{msg('添加')}}</el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" size="mini" circle @click.stop="editRow(scope.row, true)"></el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini"  circle @click.stop="deleteRow(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-col>
    <!-- end 左边 -->

    <!-- 右边 -->
    <transition name="my-animate"  
            enter-active-class="animate__animated animate__fadeInRight animate__faster" 
            leave-active-class="animate__animated animate__fadeOutRight animate__faster">
    <el-col :xs="24" :sm="18" :class="['right-box', isMinScreen?'min-charting':'']" v-if="!isMinScreen || visiableCharting">
        <div class="row-wrap" style="display:flex; justify-content: space-between;margin-right: 5px;" >
          <div class="chart-title-wrap">
              <el-link :underline="false" type="primary" @click="closeCharting()" v-if="isMinScreen && visiableCharting">
                  <i class="el-icon-back font22" ></i>
              </el-link>
            <div class="el-page-header__content">{{curr ? curr.label: ""}}</div>
          </div>
          <el-button-group>
            <el-button type="primary" size="mini" @click="add(curr)" v-if="hasPermission('setting:add')">
                <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
                <transition name="el-fade-in-linear">
                    <span v-show="!isMinScreen">{{msg('添加')}}</span>
                </transition>
            </el-button>
            <el-button type="primary" size="mini" @click="doEdit()" v-if="hasPermission('setting:edit')">
                <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
                <transition name="el-fade-in-linear">
                    <span v-show="!isMinScreen">{{msg('编辑')}}</span>
                </transition>
            </el-button>
            <el-button type="danger" size="mini" @click="doDelete()" v-if="hasPermission('setting:delete')">
                <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
                <transition name="el-fade-in-linear">
                    <span v-show="!isMinScreen">{{msg('删除')}}</span>
                </transition>
            </el-button>
          </el-button-group>
        </div>
        <div class="row-wrap" :style="{height: isMinScreen? 'auto':(docHeight-49)+'px'}" style="margin-top:-3px;margin-right:5px;" >
          <vxe-grid ref="dataTable" :height="docHeight-140" 
              resizable border
              show-overflow highlight-hover-row
              highlight-current-row highlight-hover-column highlight-current-column
              :data="childrens"
              :columns="columns"
              @cell-click="checkRow"
          ></vxe-grid>

          <pagenator
              @page-change="handlerPageChange"
              :query="query"
          >
          </pagenator>
        </div>
    </el-col>
    </transition>

  <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen" width="650px" :close-on-click-modal="false">
    <setting-edit :settings="origins" :setting="editItem" :parent="parent"
      v-on:close-dg="closeEditDialog" ref="settingEdit"/>
  </el-dialog>
</div>
</template> 

<script>
import 'animate.css';
import SettingEdit from "./SettingEdit.vue";
import Pagenator from "../../components/Pagination.vue";
 
export default {
  name: 'role',
  components: {
      SettingEdit:SettingEdit,
      Pagenator:Pagenator,
  },
  mounted:function(){
    this.refresh(true);
    this.visiableCharting = this.mi
  },
  data(){
    return{
        query:{  //查询条件
            length: 10, //每页显示的长度
            start: 0, //起始数量
            pageIndex: 1,
            pageTotal:0,//总记录数
        },
        visiableCharting: true, //是否打开聊天窗口
        settings: [],
        origins: [],
        childrens:[],
        root: {id: 0, label: this.msg("根设置")},
        curr: null,
        parent: null,
        dgTitle: null,
        dgVisible: false,
        imgBaseUrl: this.$kit.api.imgView,
        editItem: null,

        columns: [
            { type: 'checkbox', minWidth: 40 },
            {field: "id", title: this.msg("ID"), minWidth: 60},
            {field: "label", title: this.msg("名称"), minWidth: 80},
            {field: "xkey", title: this.msg("KEY"), minWidth: 80},
            {
                field: 'xtext',
                title: '内容',
                minWidth: 120,
                slots: {
                    // 使用 JSX 渲染
                    default: ({ row }) => {
                        return [
                          row.type == 2 && row.xtext? <el-image src={ this.imgBaseUrl+row.xtext }
                            preview-src-list={ [this.imgBaseUrl+row.xtext] }
                            style="width: 50px;"></el-image> : <span>{row.xtext}</span>
                        ]
                    }
                }
            },
            {field: "remark", title: this.msg("说明"), minWidth: 80},
        ],
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          var is = this.$store.state.isMinScreen;
          if(!is){
              this.openCharting();
          }
          return is;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 120;
        }
    },
    routeTab: {
        get(){
            try{
                return this.$store.state.activeMenus[this.$route.path]
            }catch{
                return null;
            }
        }
    }
  },
  methods: {
    refresh: function(refresh){  //刷新列表
      this.getSettings(refresh);
    },
    openCharting(){ //打开右侧窗口
        this.visiableCharting = true;
    },
    closeCharting(){ //关闭右侧窗口
        this.visiableCharting = false; 
    },
    getSettings(refresh){
      var _this = this;
      this.$axios.get(this.$kit.api.setting.settingRoot)
            .then(function(result){
                if(result.status){
                  var data = result.data;

                  if(_this.settings.length>0)
                    _this.settings.splice(0, _this.settings.length);
                  if(_this.origins.length>0){
                    _this.origins.splice(0, _this.origins.length);
                  }

                  _this.origins.push(_this.root);
                  
                  data.forEach( a => {
                    _this.origins.push(a);
                    _this.settings.push(a);
                  });

                  if(!_this.curr && _this.settings.length>0) _this.curr = _this.settings[0];
       
                  if(refresh){
                    _this.getChildren();
                  }

                }else{
                    _this.$message(result.msg || _this.msg('获取失败'));
                }
               
            });
    },
    getChildren(){
      var _this = this;

      if(!this.curr) return;

      this.$axios.post(this.$kit.api.setting.settingList, {start: 0, length: 9999, parentId: this.curr.id})
            .then(function(result){
                if(result.status){
                //   var data = result.data.data;

                //   if(_this.childrens.length>0)
                //     _this.childrens.splice(0, _this.childrens.length);

                //   data.forEach( a => {
                //     _this.childrens.push(a);
                //   });

                _this.childrens = result.data.data;
                _this.query.pageTotal = result.data.total;

                }else{
                    _this.$message(result.msg || _this.msg('获取失败'));
                }
               
            });
    },
    add(p){
      if(parent){
        this.parent = p;
      }else{
        this.parent = this.root;
      }
      this.editItem = {
        id:null,
        label:null,
        text:null,
        key:null,
        parentId: this.parent.id,
        remark:null,
        type:0,
        deleted: this.msg("否")
      };
      this.dgTitle=this.msg("添加设置");
      this.dgVisible = true;
    },
    editRow(row, type){
      if(type)
        this.parent = this.root;
      else{
        this.parent = this.curr;
      }
      // console.log("clicked edit", row);
      this.editItem = row;
      
      this.dgTitle = this.msg('编辑设置')+" - "+this.curr.label;
      this.dgVisible = true;
    },
    deleteRow(row){
      var _this = this;
      this.$confirm(this.fmt("确定要删除设置 %s 吗?", [row.label]), this.msg('提示'), {
        confirmButtonText: this.msg('确定'),
        cancelButtonText: this.msg('取消'),
        type: 'warning'
      }).then(() => {
        _this.$axios.post(_this.$kit.api.setting.settingDelete, [row.id])
          .then(function(result){
            _this.$message(result.msg || _this.msg('删除成功'));
            _this.refresh(true);
        });
      }).catch((err) => {
                // console.log(err);
            });

    },
    selectRow(setting, column, event){
      this.curr = setting;
      this.getChildren(); 
      this.openCharting()
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.refresh(true);
      }
      this.dgVisible = false;
    },
    //点击行，选中行
    checkRow({row, $event}){
        //如果点击checkbox 则不处理
        if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
            var table =  this.$refs.dataTable;
            table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
        }
    },
    //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
    handlerPageChange(info){
        this.query.length = info[0];
        this.query.start = info[1];
        this.getChildren();
    },
    doEdit(){

        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
            this.$message(this.msg('请选中一条数据进行操作'));
            return;
        }

        if(selectes.length > 1){
            this.$message(this.msg('只能选择一条'));
            return;
        }

        this.parent = this.curr;
        this.editItem = selectes[0];
      
        this.dgTitle = this.msg('编辑设置')+" - "+this.curr.label;
        this.dgVisible = true;

    },
    doDelete(){
        var _this = this;

        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
            this.$message(this.msg('请选中一条数据进行操作'));
            return;
        }

        let NoDelete = [];
        selectes.forEach(element => {
            if(element.deleted == '否'){
                NoDelete.push(element.label);
            }
        });
        
        if(NoDelete.length > 0){
            this.$message(this.fmt(' %s 为系统必须配置，不可删除',NoDelete));
            return;
        }

        this.$confirm(this.fmt("确定要删除设置 %s 吗?", selectes.map(e => e.label)), this.msg('提示'), {
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
        }).then(() => {
            _this.$axios.post(_this.$kit.api.setting.settingDelete, selectes.map(e => e.id))
            .then(function(result){
                _this.$message(result.msg || _this.msg('删除成功'));
                _this.refresh(true);
            });
        }).catch((err) => {
                // console.log(err);
            });
    },
  }
}
</script>

<style scoped lang="scss">
.min-charting{
    position: absolute; top:0px; left:0px; z-index: 10;
}
.chart-title-wrap{display:flex;  align-items: center;box-sizing: content-box;
}
.charting{
    margin-left: 1px;
    margin-right: -7px;
    padding-right: 5px;
}
</style>
